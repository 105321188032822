<template>
  <tyre-search
    ref="tyreSearch"
    :table-field="tyreCarTableField"
    :table-field-expand="tyreCarTableFieldExpand"
    :table-items="tyreData"
    :select-filter-options="selectFilterOptions"
    :loading="isLoading"
    :is-start="isStart"
    :is-loading-pagination="isLoadingPagination"
    :is-loading-filter="isLoadingFilter"
    :is-no-more-pagination-result="isNoMorePaginationResult"
    :select-filter-fields="selectedSelectFilterFields"
    :attribute-filter-fields="attributeFilterFields"
    :range-filter-fields="rangeFilterFields"
    @on-sort="onSortTable"
    @next-page="onNextPage"
    @input-select-filter="onInputSelectFilter"
    @search-filter="onSearchFilter"
    @clear="onClearTable"
  />
</template>

<script>
import TyreSearch from '@/components/TyreSearch';
import {
  tyreCarTableField,
  tyreCarTableFieldExpand,
  selectFilterFields,
  selectFilterFieldsDisabled,
  attributeFilterFields,
  rangeFilterFields,
} from './configs';
import { mapGetters, mapState } from 'vuex';
import { isObjectEmpty } from '@/utils';

export default {
  components: {
    TyreSearch,
  },
  data() {
    return {
      isStart: false,
      isLoading: false,
      isLoadingPagination: false,
      isNoMorePaginationResult: false,
      isLoadingFilter: false,
      selectedFilterPayload: {},
      selectedSelectFilterFields: {},
    };
  },
  computed: {
    ...mapState({
      tyreData: (state) => state.tyre.tyreDataSearch,
    }),
    ...mapGetters('tyre', ['getTyreSearchSelectFilterOptions']),
    tyreCarTableField() {
      return tyreCarTableField;
    },
    tyreCarTableFieldExpand() {
      return tyreCarTableFieldExpand;
    },
    selectFilterOptions() {
      return this.getTyreSearchSelectFilterOptions(selectFilterFields);
    },
    attributeFilterFields() {
      return attributeFilterFields;
    },
    rangeFilterFields() {
      return rangeFilterFields;
    },
    initFilterTyres() {
      if (isObjectEmpty(this.$route.query)) {
        return {};
      }
      return this.$route.query;
    },
    isSearchTyre() {
      if (!this.initFilterTyres) {
        return null;
      }
      return this.$route.query.search;
    },
  },
  watch: {
    $route(val) {
      if (!val.query.search) {
        this.$refs.tyreSearch.onResetFilter();
      } else {
        this.selectedSelectFilterFields = selectFilterFieldsDisabled;
        this.handleTyreSizeFilter();
      }
    },
  },
  async created() {
    this.selectedSelectFilterFields = selectFilterFieldsDisabled;
    if (this.tyreData.length > 0) {
      this.isLoading = true;
      this.isLoadingFilter = true;
      const seasonFilterValue = this.$route.query.search.charAt(0).toUpperCase();
      if (['S','W','A','G'].includes(seasonFilterValue)) {
        let adjustedSeasonFilterValue = seasonFilterValue;
        if (seasonFilterValue === 'G') {
          adjustedSeasonFilterValue = 'A';
        }
        this.selectedFilterPayload = {
          ...this.selectedFilterPayload,
          seasons: adjustedSeasonFilterValue,
        };
      }
      this.$store.dispatch('tyre/getTyresConcatenated', {
        ...this.initFilterTyres,
        isSearch: true,
        vehicleTypes: this.$route.query.type || '',
      }).finally(() => {
        this.isLoadingFilter = false;
      });

      try {
        await this.$store.dispatch('tyre/searchTyreResult', {
          searchValue: this.$route.query.search,
          filters: {
            ...this.initFilterTyres,
            ...this.selectedFilterPayload,
            vehicleTypes: this.$route.query.type || '',
          },
        });
        this.handleTyreSizeFilter();
      } catch (err) {
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.isLoading = false;
      }
      return;
    }
    this.selectedFilterPayload = { // initial tyre table sort
      sortField: 'bestPrice',
      sortDirection: 'ASC',
    };
    if (this.isSearchTyre) {
      this.isLoading = true;
      this.isLoadingFilter = true;
      this.$store.dispatch('tyre/getTyresConcatenated', {
        ...this.initFilterTyres,
        isSearch: true,
        vehicleTypes: this.$route.query.type || '',
      }).finally(() => {
        this.isLoadingFilter = false;
      });
      const seasonFilterValue = this.$route.query.search.charAt(0).toUpperCase();
      if (['S','W','A','G'].includes(seasonFilterValue)) {
        let adjustedSeasonFilterValue = seasonFilterValue;
        if (seasonFilterValue === 'G') {
          adjustedSeasonFilterValue = 'A';
        }
        this.selectedFilterPayload = {
          ...this.selectedFilterPayload,
          seasons: adjustedSeasonFilterValue,
        };
      }
      try {
        await this.$store.dispatch('tyre/searchTyreResult', {
          searchValue: this.$route.query.search,
          filters: {
            ...this.initFilterTyres,
            ...this.selectedFilterPayload,
            vehicleTypes: this.$route.query.type || '',
          },
        });
        this.handleTyreSizeFilter();
      } catch (err) {
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.isLoading = false;
      }
      return;
    }

    if (this.$route.query.search === '') {
      this.$store.dispatch('dialog/setShowErrorDialog', {
        status: true,
        title: 'Error search tyres',
        message: 'Please input search',
      });
      this.$router.replace(this.$i18nRoute({
        name: 'tyre',
      }));
      return;
    }

    if (this.initFilterTyres) {
      this.isLoading = true;
      this.isLoadingFilter = true;
      this.$store.dispatch('tyre/getTyresConcatenated', {
        ...this.initFilterTyres,
        isSearch: true,
        vehicleTypes: this.$route.query.type || '',
      }).finally(() => {
        this.isLoadingFilter = false;
      });
      try {
        await this.$store.dispatch('tyre/getTyres', {
          ...this.initFilterTyres,
          ...this.selectedFilterPayload,
          isSearch: true,
          vehicleTypes: this.$route.query.type || '',
        });
      } finally {
        this.isLoading = false;
      }
      return;
    }

    this.isLoading = true;
    this.isLoadingFilter = true;
    this.$store.dispatch('tyre/getTyresConcatenated', {
      ...this.initFilterTyres,
      isSearch: true,
      vehicleTypes: this.$route.query.type || '',
    }).finally(() => {
      this.isLoadingFilter = false;
    });
    try {
      await this.$store.dispatch('tyre/getTyres', {
        ...this.selectedFilterPayload,
        isSearch: true,
        vehicleTypes: this.$route.query.type || '',
      });
    } catch (err) {
      const data = err.response.data;
      const status = err.response.status;
      this.$store.dispatch('dialog/setShowErrorDialog', {
        status: true,
        title: 'Error tyres',
        message: `<p>${data['error_title']} - ${status}</p>`,
      });
    } finally {
      this.isLoading = false;
    }
  },
  mounted() {
    if (this.tyreData.length > 0) {
      this.handleTyreSizeFilter();
    }
  },
  methods: {
    getFilterPayload(filters) {
      let payload = {};
      Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key]) && filters[key].length > 0) {
          payload = {
            ...payload,
            [key]: filters[key].join(),
          };
        } else if (!Array.isArray(filters[key]) && filters[key] || filters[key] === 0) {
          payload = {
            ...payload,
            [key]: filters[key],
          };
        }
      });
      return payload;
    },
    async onSearchFilter(filters) {
      const payload = this.getFilterPayload(filters);
      this.selectedFilterPayload = {
        ...payload,
        sortField: 'bestPrice',
        sortDirection: 'ASC',
      };
      this.isLoading = true;
      this.isNoMorePaginationResult = false;

      if (this.isSearchTyre) {
        let searchPayload = this.$route.query.search;
        const seasonFilterValue = searchPayload.charAt(0).toLowerCase();
        if (['s','w','a','g'].includes(seasonFilterValue)) {
          let adjustedSeasonFilterValue = seasonFilterValue;
          if (seasonFilterValue === 'g') {
            adjustedSeasonFilterValue = 'a';
          }
          const filteredSeasons = this.selectedFilterPayload.seasons ? this.selectedFilterPayload.seasons.split(',') : [];
          if (filteredSeasons.length !== 1) {
            searchPayload = searchPayload.slice(1);
          } else if (
            filteredSeasons.length === 1 &&
            adjustedSeasonFilterValue !== filteredSeasons[0].toLowerCase()
          ) {
            searchPayload = searchPayload.slice(1);
          }
        }

        const splitSearchValues = searchPayload.split(' ');
        if (splitSearchValues.length > 1) {
          const filteredManufacturers = this.selectedFilterPayload.manufacturers ? this.selectedFilterPayload.manufacturers.split(',') : [];
          if (filteredManufacturers.length === 0 || filteredManufacturers.length > 1) {
            searchPayload = splitSearchValues[0];
          } else if (
            filteredManufacturers.length === 1 &&
            !filteredManufacturers[0].toLowerCase().includes(splitSearchValues[1].toLowerCase())
          ) {
            searchPayload = splitSearchValues[0];
          }
        }

        try {
          await this.$store.dispatch('tyre/searchTyreResult', {
            searchValue: searchPayload,
            filters: {
              ...this.selectedFilterPayload,
              isSearch: true,
              vehicleTypes: this.$route.query.type || '',
            },
          });
          this.isStart = false;
        } catch (err) {
          if (err === 'noResult') {
            return;
          }
          const data = err.response.data;
          const status = err.response.status;
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error tyres',
            message: `<p>${data['error_title']} - ${status}</p>`,
          });
        } finally {
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }
        return;
      }

      if (this.initFilterTyres) {
        try {
          await this.$store.dispatch('tyre/getTyres', {
            ...this.initFilterTyres,
            ...this.selectedFilterPayload,
            isSearch: true,
            vehicleTypes: this.$route.query.type || '',
          });
          this.isStart = false;
        } finally {
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }
        return;
      }

      try {
        await this.$store.dispatch('tyre/getTyres', {
          ...this.selectedFilterPayload,
          isSearch: true,
          vehicleTypes: this.$route.query.type || '',
        });
        this.isStart = false;
      } catch (err) {
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    },
    onInputSelectFilter(filters) {
      // disable width/height/diameter on search with search bar
      if (filters.initialSearch) {
        this.isStart = false;
        delete filters.initialSearch;
      }

      const payload = this.getFilterPayload(filters);
      this.isLoading = true;
      this.isLoadingFilter = true;
      let searchPayload = this.$route.query.search;
      const seasonFilterValue = searchPayload.charAt(0).toLowerCase();

      if (searchPayload && ['s','w','a','g'].includes(seasonFilterValue)) {
        let adjustedSeasonFilterValue = seasonFilterValue;
        if (seasonFilterValue === 'g') {
          adjustedSeasonFilterValue = 'a';
        }
        if (filters.seasons.length !== 1) {
          searchPayload = searchPayload.slice(1);
        } else if (filters.seasons.length === 1 && adjustedSeasonFilterValue !== filters.seasons[0].toLowerCase()) {
          searchPayload = searchPayload.slice(1);
        }
      }

      const splitSearchValues = searchPayload.split(' ');
      if (splitSearchValues.length > 1) {
        const filteredManufacturers = this.selectedFilterPayload.manufacturers ? this.selectedFilterPayload.manufacturers.split(',') : [];
        if (filteredManufacturers.length === 0 || filteredManufacturers.length > 1) {
          searchPayload = splitSearchValues[0];
        } else if (
          filteredManufacturers.length === 1 &&
            !filteredManufacturers[0].toLowerCase().includes(splitSearchValues[1].toLowerCase())
        ) {
          searchPayload = splitSearchValues[0];
        }
      }

      this.$store.dispatch('tyre/getTyresConcatenated', {
        ...this.initFilterTyres,
        ...payload,
        isSearch: true,
        search: searchPayload,
        vehicleTypes: this.$route.query.type || '',
      }).finally(() => {
        this.isLoadingFilter = false;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      });
    },
    async onSortTable({ activeSortCol, status }) {
      this.isLoading = true;
      this.isNoMorePaginationResult = false;
      if (status === 'none') {
        delete this.selectedFilterPayload.sortField;
        delete this.selectedFilterPayload.sortDirection;
      } else {
        this.selectedFilterPayload = {
          ...this.selectedFilterPayload,
          sortField: activeSortCol,
          sortDirection: status,
        };
      }

      try {
        if (this.isSearchTyre) {
          let searchPayload = this.$route.query.search;
          const seasonFilterValue = searchPayload.charAt(0).toLowerCase();

          if (['s','w','a','g'].includes(seasonFilterValue)) {
            let adjustedSeasonFilterValue = seasonFilterValue;
            if (seasonFilterValue === 'g') {
              adjustedSeasonFilterValue = 'a';
            }
            const filteredSeasons = this.selectedFilterPayload.seasons ? this.selectedFilterPayload.seasons.split(',') : [];
            if (filteredSeasons.length !== 1) {
              searchPayload = searchPayload.slice(1);
            } else if (filteredSeasons.length === 1
              && adjustedSeasonFilterValue !== filteredSeasons[0].toLowerCase()
            ) {
              searchPayload = searchPayload.slice(1);
            }
          }

          const splitSearchValues = searchPayload.split(' ');
          if (splitSearchValues.length > 1) {
            const filteredManufacturers = this.selectedFilterPayload.manufacturers ? this.selectedFilterPayload.manufacturers.split(',') : [];
            if (filteredManufacturers.length === 0 || filteredManufacturers.length > 1) {
              searchPayload = splitSearchValues[0];
            } else if (
              filteredManufacturers.length === 1 &&
            !filteredManufacturers[0].toLowerCase().includes(splitSearchValues[1].toLowerCase())
            ) {
              searchPayload = splitSearchValues[0];
            }
          }

          await this.$store.dispatch('tyre/searchTyreResult', {
            searchValue: searchPayload,
            filters: {
              ...this.selectedFilterPayload,
              isSearch: true,
              vehicleTypes: this.$route.query.type || '',
            },
          });
        } else {
          await this.$store.dispatch('tyre/getTyres', {
            ...this.initFilterTyres,
            ...this.selectedFilterPayload,
            isSearch: true,
            vehicleTypes: this.$route.query.type || '',
          });
        }
        this.isStart = false;
      } catch (err) {
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async onNextPage(page) {
      if (this.isSearchTyre) {
        this.isLoadingPagination = true;
        let searchPayload = this.$route.query.search;
        const seasonFilterValue = searchPayload.charAt(0).toLowerCase();
        if (['s','w','a','g'].includes(seasonFilterValue)) {
          let adjustedSeasonFilterValue = seasonFilterValue;
          if (seasonFilterValue === 'g') {
            adjustedSeasonFilterValue = 'a';
          }
          const filteredSeasons = this.selectedFilterPayload.seasons ? this.selectedFilterPayload.seasons.split(',') : [];
          if (filteredSeasons.length !== 1) {
            searchPayload = searchPayload.slice(1);
          } else if (filteredSeasons.length === 1
            && adjustedSeasonFilterValue !== filteredSeasons[0].toLowerCase()
          ) {
            searchPayload = searchPayload.slice(1);
          }
        }

        const splitSearchValues = searchPayload.split(' ');
        if (splitSearchValues.length > 1) {
          const filteredManufacturers = this.selectedFilterPayload.manufacturers ? this.selectedFilterPayload.manufacturers.split(',') : [];
          if (filteredManufacturers.length === 0 || filteredManufacturers.length > 1) {
            searchPayload = splitSearchValues[0];
          } else if (
            filteredManufacturers.length === 1 &&
            !filteredManufacturers[0].toLowerCase().includes(splitSearchValues[1].toLowerCase())
          ) {
            searchPayload = splitSearchValues[0];
          }
        }

        try {
          await this.$store.dispatch('tyre/searchTyreResultNextPage', {
            searchValue: searchPayload,
            filters: {
              ...this.selectedFilterPayload,
              page: page,
              isSearch: true,
              vehicleTypes: this.$route.query.type || '',
            },
          });
        } catch (err) {
          if (err === 'noResult') {
            this.isNoMorePaginationResult = true;
            this.isLoadingPagination = false;
            return;
          }

          const data = err.response.data;
          const status = err.response.status;
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error tyres',
            message: `<p>${data['error_title']} - ${status}</p>`,
          });
        } finally {
          this.isLoadingPagination = false;
        }
        return;
      }

      if (this.initFilterTyres) {
        this.isLoadingPagination = true;
        try {
          await this.$store.dispatch('tyre/getTyresNextPage', {
            ...this.initFilterTyres,

            ...this.selectedFilterPayload,
            isSearch: true,
            page: page,
            vehicleTypes: this.$route.query.type || '',
          });
        } catch (err) {
          if (err === 'noResult') {
            this.isNoMorePaginationResult = true;
            this.isLoadingPagination = false;
            return;
          }
          const data = err.response.data;
          const status = err.response.status;
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Error tyres',
            message: `<p>${data['error_title']} - ${status}</p>`,
          });
        } finally {
          this.isLoadingPagination = false;
        }
        return;
      }

      this.isLoadingPagination = true;
      try {
        await this.$store.dispatch('tyre/getTyresNextPage', {
          ...this.selectedFilterPayload,
          isSearch: true,
          page: page,
          vehicleTypes: this.$route.query.type || '',
        });
      } catch (err) {
        if (err === 'noResult') {
          this.isNoMorePaginationResult = true;
          this.isLoadingPagination = false;
          return;
        }
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.isLoadingPagination = false;
      }
    },
    onClearTable() {
      this.selectedSelectFilterFields = selectFilterFields;
      this.isStart = true;
      // this.isLoadingFilter = true;
      // this.isLoading = true;
      if (this.$route.name === 'tyre-search' && this.$route.query.search !== '') {
        this.$router.replace(this.$i18nRoute({
          name: 'tyre-search',
          query: {
            search: '',
          },
        }));
      }
      // this.$store.dispatch('tyre/getTyresConcatenated', {
      //   ...this.initFilterTyres,
      //   isSearch: true,
      // }).finally(() => {
      //   this.isLoadingFilter = false;
      //   setTimeout(() => {
      //     this.isLoading = false;
      //   }, 500);
      // });
      this.$store.dispatch('tyre/clearTyresTable',{
        isSearch: true,
      });
    },
    handleTyreSizeFilter() {
      // Set tyre size filter if matchcode is old format
      const tyreDataWidths = [...new Set(this.tyreData.map((item) => item.width))];
      const tyreDataAspectRatios = [...new Set(this.tyreData.map((item) => item.aspectRatio))];
      const tyreDataDiameters = [...new Set(this.tyreData.map((item) => item.diameter))];
      if (
        tyreDataWidths.length === 1 &&
        tyreDataAspectRatios.length === 1 &&
        tyreDataDiameters.length === 1
      ) {
        this.$refs.tyreSearch.setTyreSizeFilter({
          widths: tyreDataWidths,
          aspectRatios: tyreDataAspectRatios[0] !== '' ? tyreDataAspectRatios : [' '],
          diameters: tyreDataDiameters,
        });
      }
    },
  },
};
</script>